body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
div,
button {
  font-family: "Nunito", serif;
  color: #4f4f4f;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

.white-text {
  color: #ffffff;
}

.blue-text {
  color: #1576ec;
}

#wrapper {
  height: 100%;
  overflow: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.rounded-circle-icon {
  width: 30px;
  height: 30px;
  background: #e8eef8;
  border-radius: 50%;
  color: #2a3b8f;
  font-size: 13px;
  padding-top: 7px;
  text-align: center !important;
}

.rounded-circle-icon.active,
rounded-circle-icon:active {
  color: #fff;
  background-color: #2a3b8f;
}

.small-shadow {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.41);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.41);
}

.PhoneInputInput {
  border: 0;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputInput:focus {
  outline: none;
}

.dark-bg {
  background: linear-gradient(rgba(12, 12, 12, 0.397), rgba(12, 12, 12, 0.479));
}

.medium-shadow {
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.38);
}

.vertical-divider {
  position: absolute;
  width: 145px;
  height: 10px;
  left: 534px;
  top: 247px;

  border: 1px solid #1576ec;
  transform: rotate(90deg);
}

.rounded-circle-username {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.hv-100 {
  height: 100vh;
}

/* .right 
{
  order:1;
  margin-left:auto;
} */

.btn-primary {
  color: #fff !important;
  background-color: #2a3b8f !important;
  border-color: #2a3b8f !important;
}

.btn-white {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-outline-primary {
  color: #2a3b8f !important;
  border-color: #2a3b8f !important;
}

.default-link {
  color: #2a3b8f !important;
  text-decoration: none !important;
}

.text-default {
  color: #2a3b8f !important;
}

.form-check-input:checked {
  background-color: #2a3b8f !important;
  border-color: #2a3b8f !important;
}

.disable-cursor {
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.dashboard-main {
  background-color: #f9fafd;
}

.dashboard-home {
  display: flex;
  flex-direction: column;
  width: 98%;
}

.sidebar-area {
  width: 15%;
}

.text-default-color {
  color: #2a3b8f;
}

.flex {
  display: flex;
}

.flex-c {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.logo-area {
  padding-left: 1.2rem;
}

.sidebar-nav-item-list {
  display: flex;
  flex-direction: column;
}

.dashboard-navbar {
  background-color: white;
  box-shadow: 0px 2px 12px rgb(0 0 0 / 4%);
}

.dashboard-navbar .right {
  order: 1;
  margin-left: auto;
  display: flex;
}

.notification-area {
  font-size: 1.2rem;
  margin-right: 2rem;
  position: relative;
}

.notification-dot {
  width: 6px;
  background: red;
  height: 6px;
  position: absolute;
  border-radius: 50%;
  top: 2px;
  left: 12px;
  border: 1px solid white;
}

.username-area {
  margin-top: 0.2rem;
  font-size: 1rem;
}

.avatar {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: gray;
  margin-left: 1rem;
}

.filter-row .right {
  order: 1;
  margin-left: auto;
}

.filter-row .left div,
.filter-row .right div {
  margin-right: 1rem !important;
}

.custom-select .MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.custom-select .MuiInputBase-root {
  background: white !important;
  border: 1px solid rgb(235, 235, 235) !important;
}

.custom-select .MuiInputLabel-root {
  position: relative !important;
}

.custom-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Nunito", serif !important;
  font-weight: 600 !important;
}

.total-card {
  padding: 1rem;
  width: 25%;
  margin-right: 1rem;
}

.total-card .right .top {
  font-size: 0.9rem;
  color: rgb(177, 177, 177);
  margin-bottom: 1rem;
  height: 1.5rem;
  padding-top: 0.6rem;
}

.total-card .right .bottom {
  font-size: 1.4rem;
  font-weight: 600;
}


.total-card-final {
  padding: 1rem;
}

.total-card-final .right .top {
  font-size: 0.9rem;
  color: rgb(177, 177, 177);
  margin-bottom: 1rem;
  height: 1.5rem;
  padding-top: 0.6rem;
}

.total-card-final .right .bottom {
  font-size: 1.4rem;
  font-weight: 600;
}

.map-component svg {
  width: 280px;
  height: 280px;
}

.regional-distribution-card .top {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.regional-distribution-card .left,
.regional-distribution-card .right {
  width: 50%;
}

.regional-distribution-card .bottom .left {
  width: 50%;
}

.regional-distribution-card .bottom .right {
  width: 50%;
}

.regional-card-bottom .left,
.regional-card-bottom .left {
  width: 50%;
}

.regional-information .top {
  font-size: 0.8rem;
  font-weight: 600;
}

.district-row {
  margin-top: 0.8rem;
}

.district-row .right {
  order: 1;
  margin-left: auto;
  text-align: right;
  font-weight: 600;
}

.chart-row .left {
  width: 45%;
}

.chart-row .right {
  width: 55%;
}

.card-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

::-webkit-scrollbar {
  width: 8px;
  padding: 1rem;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2a3b8f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192457;
}

.vis-background {
  display: none !important;
}

.gender-distribution .bottom {
  align-items: center;
  justify-content: center;
  position: relative;
}

.total-description {
  position: absolute;
  text-align: center;
}

.total-description .bottom {
  font-weight: 600;
  font-size: 1.7rem;
  margin-top: 1rem;
}

.distribution-key {
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  right: unset;
  /* margin-top: 1rem; */
  bottom: -1rem;
}

.distribution-key div {
  margin-top: 1rem;
  margin-right: 1rem;
}

.distribution-key div:last-of-type {
  margin-top: 1rem;
  margin-right: unset;
}

.custom-data-table .MuiPaper-root {
  box-shadow: unset !important;
}

.MUIDataTableSelectCell-headerCell {
  background-color: black !important;
}

/* .custom-data-table .tss-1cdcmys-MUIDataTable-responsiveBase
  {
    height:calc(90vh - 300px)!important;score-card
  } */

.custom-data-table .MuiTypography-root {
  font-size: 1rem !important;
  font-weight: 600 !important;
  margin-bottom: 1.5rem !important;
  font-family: "Nunito", serif !important;
}

.key-color {
  border-radius: 50%;
  padding: 0.3rem;
  margin-right: 0.5rem;
  display: inline-block;
}

.data-row div:last-of-type {
  margin-right: unset;
}

.settings-table .MuiTableCell-paddingCheckbox {
  display: none !important;
}

.reports-table .MuiTableCell-paddingCheckbox {
  display: none !important;
}

.custom-data-table .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  background-color: #fafafb !important;
  color: #8492a6 !important;
  font-weight: 700 !important;
}

.custom-data-table .tss-1akey0g-MUIDataTableHeadCell-data {
  display: inline-block;
  font-size: 0.9rem;
  text-transform: initial !important;
  font-weight: 700;
  color: #8492a6;
  text-align: center;
}

.custom-data-table .MuiButtonBase-root {
  /* padding-left:unset!important; */
}

.custom-data-table .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  min-width: unset !important;
}

.custom-data-table .css-1ex1afd-MuiTableCell-root {
  padding: 1.5rem !important;
  border-bottom: 1px solid rgb(242 242 242) !important;
}

.custom-data-table .tss-1qtl85h-MUIDataTableBodyCell-root {
  font-size: 0.8rem !important;
  font-weight: 600;
}

.applicant-list .bottom-list {
  height: calc(100% - 150px);
  overflow-y: auto;
}

.custom-pagination .Mui-selected {
  background-color: #2a3b8f !important;
  color: white;
}

::placeholder {
  font-style: "Nunito", serif !important;
  font-size: 1rem;
  color: #abb3bf !important;
  font-weight: 400;
}

.add-staff-btn:hover {
  cursor: pointer;
}

.add-staff-form-input {
  width: calc(100% - 1.6rem);
  border: 1px solid #dfdfdf;
  border-radius: 0.3rem;
  padding: 0.8rem;
}

.custom-checkbox .MuiFormControlLabel-label {
  font-size: 0.8rem;
  font-family: inherit !important;
}

.settings-nav-item:hover {
  cursor: pointer;
  background-color: white;
}

.no-hover:hover {
  background-color: inherit !important;
}

input:focus {
  outline: none !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 300 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 300;
}

.applicant-card:hover {
  cursor: pointer;
  background-color: white !important;
}

.score-card:hover {
  cursor: pointer;
}

.filter-select {
  padding: 0.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 2rem;
  width: 100%;
  border: 1px solid #EEF1F3;
  color: #4F4F4F;
  border-radius: 0.3rem;
  font-weight: 600;
  font-family: inherit;
  font-size: 14px;

}

.custom-slider .MuiSlider-thumb {
  display: none !important;
}

.custom-slider .MuiSlider-rail {
  display: none !important;
}

.custom-slider .MuiSlider-track {
  border-width: 4px !important;
  border-color: #407bff !important;
}

.custom-slider-wrapper .Mui-disabled {
  color: #407bff !important;
}

.custom-slider .Mui-disabled {
  color: unset !important;
}

.slider-reverse .MuiSlider-track {
  left: unset !important;
  right: 0% !important;
  border-width: 4px !important;
}

.slider-reverse .MuiSlider-thumb {
  display: none !important;
}

.slider-reverse .MuiSlider-rail {
  display: none !important;
}

.slider-reverse .Mui-disabled {
  color: unset !important;
}

.slider-reverse-wrapper .Mui-disabled {
  color: rgb(255 50 21) !important;
}

.value-slider .MuiSlider-thumb {
  display: none !important;
}

.value-slider .MuiSlider-rail {
  display: none !important;
}

.value-slider .MuiSlider-track {
  border-width: 4px !important;
  border-color: #ffa000 !important;
}

.value-slider-wrapper .Mui-disabled {
  color: #ffa000 !important;
}

.value-slider .Mui-disabled {
  color: unset !important;
}

.vertical-bar-series-example rect {
  rx: 5;
}

.range-input {
  font-family: inherit !important;
}

.no-hover:hover {
  cursor: pointer;
  background-color: unset !important;
}

.custom-default-btn {
  margin-right: unset !important;
}

.no-bottom {
  margin-bottom: unset !important;
}

.rv-xy-plot__axis__tick__text {
  font-weight: 700 !important;
  font-size: 14px !important;
}

.rv-xy-plot__series--label-text {
  /* text-anchor: end;
    dominant-baseline: text-after-edge; */
  font: bold 16px "Nunito", serif;
  fill: black;
  text-anchor: end;
}

.manual-score-wrapper div:last-child {
  border-bottom: unset;
}



@media (max-width: 1000px) {
  .applicant-icon {
    display: none;
  }

  .applicant-card {
    flex-direction: column;
  }

}

/* .drawer-item:hover
  {
    cursor:pointer;
  } */
/* .regional-information .bottom . */