body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --light-gray: rgb(228, 228, 228);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.regional-distribution-card svg {
  width: 280px;
  height: 280px;
  stroke: #989898;
}


.map-component path {
  fill: #f0f0f0;
  cursor: pointer;
  outline: none;
  stroke: #2a3b8f;
}

.map-component path:hover {
  fill: rgb(170, 182, 244);
}

.map-component path:focus {
  fill: rgb(42, 59, 143);
}

.map-component path[aria-checked="true"] {
  fill: rgb(42, 59, 143);
}

.map-component path[aria-current="true"] {
  fill: rgb(42, 59, 143);
}


.regional-distribution-card path {
  fill: #fafafa;
  cursor: pointer;
  outline: none;
}

.regional-distribution-card path:hover {
  fill: #e5e5e5;
}

.regional-distribution-card path:focus {
  fill: rgb(42, 59, 143);
}

.regional-distribution-card path[aria-checked="true"] {
  fill: rgb(42, 59, 143);
}

.regional-distribution-card path[aria-current="true"] {
  fill: rgb(42, 59, 143);
}

.custom-graph path {
  stroke: unset !important;
}

button:hover {
  cursor: pointer;
}

.options .css-b62m3t-container {
  width: 100% !important;
}

.custom-tabs {
  border-bottom: 1px solid var(--light-gray);
}

.custom-tabs .css-1aquho2-MuiTabs-indicator {
  background-color: #2a3b8f !important;
  height: 3px;
}

.custom-tabs .MuiTab-root {
  text-transform: unset !important;
  font-family: inherit !important;
  font-weight: "600";
  font-size: "1.5rem" !important;
}

.custom-tabs .Mui-selected {
  color: #2a3b8f !important;
}

.approve-btn {
  padding: 0.5rem;
  background-color: #2a3b8f;
  color: white;
  width: 12rem;
  font-size: 0.9rem;
  border: 0px;
  border-radius: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.score-row div {
  margin-right: "1rem";
}

.score-row div:last-of-type {
  margin-right: unset;
}

.custom-line-series .rv-xy-plot__grid-lines__line {
  stroke: #e9e9eb !important;
  stroke-dasharray: 4;
}

#analysis-table th {
  color: white;
  background-color: #2a3b8f !important;
  font-size: 1.2rem;
  padding: 1rem;
}

#analysis-table td {
  border-bottom: 1px solid rgb(205, 205, 205);
}

#analysis-table table {
  border-spacing: 0 !important;
  width: 100%;
}

#analysis-table tr,
#analysis-table td {
  border-spacing: 0 !important;
}

.settings-table ::-webkit-scrollbar {
  width: 8px;
  padding: 1rem;
  height: 6px !important;
}