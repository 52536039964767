.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    width: '100%'
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 2rem;
    outline: none;
    width: 45%;
    border-radius: 15px;

}

.modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.close-btn-wrapper {
    order: 1;
    margin-left: auto;
}